import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from '@/plugins/vue-i18n'
import vuetify from '@/plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import ApiService from '@/services/api.service'
import LottieAnimation from "@/plugins/lottie-anim";
import '@/plugins/globalComponents'
import '@/plugins/vue-multiselect'
import '@/plugins/autocomplete'
import '@/helpers/vue-filters'
import '@/plugins/directives'
import '@/plugins/vue-mask'
import '@/plugins/v-tootip'
import '@/plugins/moment'
import packageJson from '../package.json'
import ModalConfirmReload from "@/components/ui/modals/ModalConfirmReload.vue";

Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.component('apex-chart', VueApexCharts)

Vue.http = Vue.prototype.$http = ApiService

const currentVersion = packageJson.version;
const versionKey = 'appVersion';
const savedVersion = localStorage.getItem(versionKey);

if (savedVersion !== currentVersion) {
  const authTokenKey = 'id_token';
  const authToken = localStorage.getItem(authTokenKey);
  localStorage.clear();
  if (authToken) localStorage.setItem(authTokenKey, authToken);
  localStorage.setItem(versionKey, currentVersion);
  if(!localStorage.getItem('reload_modal_shown')){
    document.addEventListener('DOMContentLoaded', () => {
      store.dispatch('ModalModule/open', {
        extends: ModalConfirmReload,
      })
    })
  }
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  LottieAnimation,
  render: h => h(App),
}).$mount('#app')
