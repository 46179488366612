<template>
  <v-treeview
    class="ui-treeview"
    :items="items"
    :active.sync="active"
    :search="search"
    expand-icon="mdi-chevron-down"
    hoverable
    activatable
    dense
    return-object
    open-on-click
  >
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </v-treeview>
</template>

<script>
export default {
  name: 'UITreeView',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      active: [],
    }
  },
  watch: {
    active: {
      handler(value) {
        this.$emit('input', value)
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.ui-treeview {
  .mdi-chevron-down {
    color: #c6c6c6;
    font-size: 20px;
  }
  .v-treeview-node {
    &__toggle:after {
      background: none;
    }
    &__root:before {
      border-radius: 8px;
    }
    &__root:hover {
      color: var(--v-blue500-base);
      cursor: pointer;
    }
    &--active {
      .v-treeview-node {
        &__label {
          color: var(--v-primary-base) !important;
        }
      }
    }
    &__label {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: var(--v-black-darken6);
    }
    &__children {
      .v-treeview-node {
        &__label {
          font-size: 12px;
          color: var(--v-black-darken3);
        }
      }
    }
  }
}
</style>
