export default [
  {
    path: '/general-report',
    name: 'pageGeneralReportList',
    meta: { title: 'generalReport.listTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['general_report'] },
    component: () => import('@/pages/general-report'),
  },
  {
    path: '/general-report-old',
    name: 'pageGeneralReportListOld',
    meta: { title: 'generalReport.listTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['general_report'] },
    component: () => import('@/pages/general-report'),
  },
  {
    path: '/general-report-new',
    name: 'pageGeneralReportListNew',
    meta: { title: 'generalReport.menuItemNameNew', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['general_report'] },
    component: () => import('@/pages/general-report-new'),
  },
]