import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import { defaultFilters } from '@/models/CostsReport/helpers'
import TableOptions from '@/models/TableOptions'
import {omitEmptyDeepFilters} from "@/helpers/filters";
import playerReportsRepository from "@/repository/generatedRepository/playerReportsRepository";
import moment from "moment";
import {DATE_FORMAT_SHORT} from "@/constants/date";
import i18n from "@/plugins/vue-i18n";
const {saveAs} = require('file-saver')

const state = {
  list: [],
  filters: StorageService.get(storageKeys.COSTS_REPORT_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.COSTS_REPORT_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const response = await playerReportsRepository.costsReportExport(
      {...appliedFilters}, {responseType: 'blob'},)
    const blob = new Blob([response.data], {type: response.headers['content-type']})
    const filename = `${i18n.tc('costsReport.listTitle')} ${
      moment(context.getters.filters.filter.dateFrom).format(DATE_FORMAT_SHORT)}-${
      moment(context.getters.filters.filter.dateTo).format(DATE_FORMAT_SHORT)}.xlsx`
    saveAs(blob, filename)
    return response
  },
  resetFilters(context, filters){
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.COSTS_REPORT_FILTERS, filters)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.COSTS_REPORT_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.COSTS_REPORT_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
