export default {
  ANSWER_FILTERS: 'filters_answer',
  BLACK_PHONE_FILTERS: 'filters_black_phone',
  CALL_CONTROLLER: 'filters_call_controller',
  CAMPAIGN_FILTERS: 'filters_campaign',
  CAMPAIGN_FILTERS_QUEUE: 'filters_campaign_queue',
  CITY_FILTERS: 'filters_city',
  DASHBOARD_FILTERS: 'filters_dashboard',
  DASHBOARD_EFFICIENCY_CHART: 'filters_dashboard_efficiency_chart',
  DELIVERY_COURIER_FILTERS: 'filters_delivery_courier',
  DELIVERY_EXCLUDED_RULES_FILTERS: 'filters_delivery_excluded_rules',
  DELIVERY_INTERVAL_FILTERS: 'filters_delivery_interval',
  DELIVERY_POINT_FILTERS: 'filters_delivery_point',
  DELIVERY_POINTS_ORDER_FILTERS: 'filters_delivery_points_order',
  DELIVERY_POST_FILTERS: 'filters_delivery_post',
  DELIVERY_SERVICE_CITY_FILTERS: 'filters_service_cities',
  DELIVERY_SERVICES_FILTERS: 'filters_delivery_service',
  DELIVERY_WAREHOUSE_FILTERS: 'filters_warehouses',
  DELIVERY_WEEKEND_FILTERS: 'filters_delivery_weekends',
  DISTRICT_FILTERS: 'filters_district_settings',
  FINANCE_SETTINGS_FILTERS: 'filters_finance_settings',
  GROUP_TYPES_FILTERS: 'filters_group_types',
  KLN_ASSESSMENT_FILTERS: 'filters_kln_assessment',
  KLN_OPERATOR_ASSESSMENT_FILTERS: 'filters_kln_operator_assessment',
  LANDING_FILTERS: 'filters_landing',
  LANGUAGE_FILTERS: 'filters_language_country',
  LEGAL_PERSONS_STATS_FILTERS: 'filters_legal_persons_stats',
  LOG_FILTERS_AUTH: 'filters_log_auth',
  LOG_FILTERS_ORDER: 'filters_log_order',
  OFFER_COLD_STATISTIC_FILTERS: 'filters_offer_cold_statistic',
  OFFER_GROUPS_FILTERS: 'filters_offer_groups',
  OFFER_NICHES_FILTERS: 'filters_offer_niches',
  OFFER_RATING_FILTERS: 'filters_rating_offers',
  OFFER_STATISTICS_FILTERS: 'filters_offer_statistics',
  OFFER_TICKETS_FILTERS: 'filters_offer_tickets',
  OFFERS_NORMS_FILTERS: 'filter_offer_norms',
  OPERATOR_GRADES_FILTERS: 'filters_operator_grades',
  GRADE_HISTORY_FILTERS: 'filters_grade_history',
  ORDER_FILTERS: 'filters_orders',
  ORDER_OPERATOR_FILTERS: 'filters_orders_operator',
  ORDER_FRAUD_FILTERS: 'filters_order_fraud',
  ORDER_HANGING_RANSOM_FILTERS: 'filters_hanging_ransom',
  ORDER_LOGISTICS_FILTERS: 'filters_order_logistics',
  ORDER_SELECTED_COLUMNS: 'columns_order',
  ORDER_TAG_FILTERS: 'filters_order_tag',
  ORDER_TRASH_CHECKED_FILTERS: 'filters_order_trash_checked',
  ORDER_TRASH_DISPUTE_FILTERS: 'filters_order_trash_dispute',
  ORDER_TRASH_FILTERS: 'filters_order_trash',
  PAYMENT_HOLD_FILTERS: 'filters_payment_hold',
  PAYMENT_PROFIT_FILTERS: 'filters_payment_profit',
  PHONE_CODES_FILTERS: 'filters_phone_codes',
  PREPAYMENT_FILTERS: 'filters_prepayment',
  PREPAYMENT_FILTERS_TWO: 'filters_prepayment_two',
  PREPAYMENT_FILTERS_TEAMLEAD: 'filters_prepayment_teamlead',
  PREPAYMENT_FILTERS_TEAMLEAD_TWO: 'filters_prepayment_teamlead_two',
  PRODUCT_GIFTS_FILTERS: 'filters_product_gifts',
  PRODUCT_SETS_FILTERS: 'filters_product_sets',
  PRODUCT_TYPES_FILTERS: 'filters_product_type',
  QUESTIONNAIRES_FILTERS: 'filters_questionnaires',
  QUESTIONNAIRES_KNL_FILTERS: 'filters_questionnaires_knl',
  STATISTICS_KLN_FILTERS: 'filters_statistics_kln',
  QUESTIONNAIRES_ORDER_FILTERS: 'filters_questionnaires_order',
  REGION_FILTERS: 'filters_region_settings',
  SALARY_BONUS_FILTERS: 'filters_salary_bonus',
  SALES_MODEL_FILTERS: 'filters_sales_model',
  SALES_SCRIPT_FILTERS: 'filters_sales_script',
  SALES_SCRIPT_TEMPLATE_FILTERS: 'filters_sales_script_template',
  SMS_CAMPAIGN_FILTERS: 'sms_filters_campaign',
  SMS_TEMPLATE_FILTERS: 'sms_template_filters',
  STATUS_REASON_FILTERS: 'filters_status_reason',
  USER_FILTERS: 'filters_users',
  USER_GROUP_FILTERS: 'filters_user_groups',
  USER_GROUP_TYPE_FILTERS: 'filters_user_group_types',
  USER_PENALTY_FILTERS: 'filters_user_penalty',
  USER_ROLES_FILTERS: 'filters_user_roles',
  WEB_ANALYTICA_FILTERS: 'filters_web_analytica',
  WORK_TIME_FILTERS: 'filters_work_time',
  ORDER_AVERAGE_CHECK: 'filters_average_check',
  DELIVERY_SERVICE_ANALYTIC: 'filters_delivery_service_analytic',
  ORGANIZATION_FILTERS: 'filters_organization',
  DISTRIBUTION_CHECK_FILTERS: 'filters_distribution',
  USER_SALARY_FILTERS: 'filters_user_salary',
  BUYBACK_DYNAMICS_FILTERS: 'filters_buyback_dynamics',
  REJECTION_STATISTICS_FILTERS: 'filters_rejection_statistics',

  // Generated storage keys under this comment. DO NOT REMOVE ANY COMMENTS below
  // __auto_generated_storage_keys__
  COSTS_REPORT_FILTERS: 'filters_costs_report',
  OFFER_PARTNERS_FILTERS: 'filters_offer_partners',
  SPENDS_PARSING_LOG_FILTERS: 'filters_spends_parsing_log',
  PARTNERS_SPENDS_TABLE_FILTERS: 'filters_partners_spends_table',
  TRAFFIC_SOURCES_FILTERS: 'filters_traffic_sources',
  LANDINGS_FILTERS: 'filters_landings',
  BUDGET_REPORT_FILTERS: 'filters_budget_report',
  PARTNER_REPORT_FILTERS: 'filters_partner_report',
  ROLES_FILTERS: 'filters_roles',
  PARTNER_GROUPS_FILTERS: 'filters_partner_groups',
  PARTNER_TAGS_FILTERS: 'filters_partner_tags',
  OFFER_PARTNER_TAGS_FILTERS: 'filters_offer_partner_tags',
  PARTNER_INFO_FILTERS: 'filters_partner_info',
  GENERAL_REPORT_FILTERS: 'filters_general_report',
  GENERAL_REPORT_NEW_FILTERS: 'filters_general_report_new',
  COHORT_ANALYSIS_FILTERS: 'filters_cohort_analysis',
  GENERAL_REPORT_DATA: 'general_report_data',
  GENERAL_REPORT_NEW_DATA: 'general_report_new_data',
  COHORT_ANALYSIS_DATA: 'cohort_analysis_data',
  BUDGET_REPORT_DATA: 'budget_report_data',
  DASHBOARD_CHART_DATA: 'dashboard_chart_data',
  DASHBOARD_TOP_PARTNERS_DATA: 'dashboard_top_partners_data',
  DASHBOARD_TOP_COUNTRIES_DATA: 'dashboard_top_countries_data',
  CURRENCIES_FILTERS: 'filters_currencies',
  PLAYERS_REPORTS_FILTERS: 'filters_players_reports',
  PLAYERS_REPORTS_API_FILTERS: 'filters_players_reports_api',
  PLAYER_REPORT_FILTERS: 'filters_player_report',
  PLAYERS_FILTERS: 'filters_players',
  PLAYER_FILTERS: 'filters_player',
  POSTBACKS_FILTERS: 'filters_postbacks',
  EVENTS_FILTERS: 'filters_events',
  PARTNERS_SPENDS_FILTERS: 'filters_partners_spends',
  USERS_FILTERS: 'filters_users',
  CLICKS_FILTERS: 'filters_clicks',
  OFFER_FILTERS: 'filters_offer',
  COUNTRY_FILTERS: 'filters_country',
  PRODUCTS_FILTERS: 'filters_products',
  PAYMENT_MODELS_FILTERS: 'filters_payment_models',
  ADVERTISERS_FILTERS: 'filters_advertisers',
  PARTNERS_FILTERS: 'filters_partners',
}
