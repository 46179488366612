/*
    Danger!
    You should not remove any comments from this file, if comments will be removed generator will be broken
*/

import Vue from 'vue'
import Vuex from 'vuex'

import AuthModule from '@/store/modules/Auth.module'
import FileModule from '@/store/modules/File.module'
import ModalModule from '@/store/modules/Modal.module'
import NotificationModule from '@/store/modules/Notification.module'
import ProfileModule from '@/store/modules/Profile.module'
import SettingModule from '@/store/modules/Setting.module'

// Generated modules imported under this 2 comments. DO NOT REMOVE ANY COMMENTS
// __generator_imports__
import CostsReportModule from '@/store/modules/CostsReport.module'
import OfferPartnersModule from '@/store/modules/OfferPartners.module'
import SpendsParsingLogModule from '@/store/modules/SpendsParsingLog.module'
import PartnersSpendsTableModule from '@/store/modules/PartnersSpendsTable.module'
import TrafficSourcesModule from '@/store/modules/TrafficSources.module'
import LandingsModule from '@/store/modules/Landings.module'
import BudgetReportModule from '@/store/modules/BudgetReport.module'
import PartnerReportModule from '@/store/modules/PartnerReport.module'
import RolesModule from '@/store/modules/Roles.module'
import PartnerGroupsModule from '@/store/modules/PartnerGroups.module'
import GeneralReportModule from '@/store/modules/GeneralReport.module'
import PlayersReportsModule from '@/store/modules/PlayersReports.module'
import PlayersModule from '@/store/modules/Players.module'
import CurrenciesModule from '@/store/modules/Currencies.module'
import PostbacksModule from '@/store/modules/Postbacks.module'
import EventsModule from '@/store/modules/Events.module'
import PartnersSpendsModule from '@/store/modules/PartnersSpends.module'
import UsersModule from '@/store/modules/Users.module'
import ClicksModule from '@/store/modules/Clicks.module'
import OfferModule from '@/store/modules/Offer.module'
import PartnersModule from '@/store/modules/Partners.module'
import CountryModule from '@/store/modules/Country.module'
import PaymentModelsModule from '@/store/modules/PaymentModels.module'
import AdvertisersModule from '@/store/modules/Advertisers.module'
import ProductsModule from '@/store/modules/Products.module'
import PlayerModule from "@/store/modules/Player.module";
import DashboardModule from "@/store/modules/Dashboard.module";
import PermissionModule from "@/store/modules/Permission.module";
import CohortAnalysisModule from "@/store/modules/CohortAnalysis.module";
import PartnerTagsModule from "@/store/modules/PartnerTags.module";
import PartnerInfoModule from "@/store/modules/PartnerInfo.module";
import OfferPartnerTagsModule from "@/store/modules/OfferPartnerTags.module";
import PartnerAffiliateModule from "@/store/modules/PartnerAffiliate.module";
import GeneralReportNewModule from "@/store/modules/GeneralReportNew.module";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    AuthModule,
    FileModule,
    ModalModule,
    NotificationModule,
    ProfileModule,
    SettingModule,

    // Generated modules under this comment. DO NOT REMOVE ANY COMMENTS
    // __imported_entities__
    CostsReportModule,
    OfferPartnersModule,
    SpendsParsingLogModule,
    PartnersSpendsTableModule,
    TrafficSourcesModule,
    LandingsModule,
    BudgetReportModule,
    PartnerReportModule,
    RolesModule,
    PartnerGroupsModule,
    GeneralReportModule,
    GeneralReportNewModule,
    PlayersReportsModule,
    PlayersModule,
    PlayerModule,
    CurrenciesModule,
    PostbacksModule,
    EventsModule,
    PartnersSpendsModule,
    UsersModule,
    ClicksModule,
    OfferModule,
    PartnersModule,
    CountryModule,
    PaymentModelsModule,
    AdvertisersModule,
    ProductsModule,
    DashboardModule,
    PermissionModule,
    CohortAnalysisModule,
    PartnerTagsModule,
    PartnerInfoModule,
    OfferPartnerTagsModule,
    PartnerAffiliateModule
    },
})
