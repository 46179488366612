import moment from "moment";
import {DATE_FORM_FORMAT} from "@/constants/date";

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    advertiser: {
      id: {
        value: '',
        operator: 'in'
      }
    },
    postbackPartner: {
      id: {
        value: '',
        operator: 'in'
      }
    },
    trafficSource: {
      id: {
        value: '',
        operator: 'in'
      }
    },
    createdAt: {
      value: `${moment().subtract(2, 'weeks').format(DATE_FORM_FORMAT)} 00:00:00|${
        moment().format(DATE_FORM_FORMAT)} 23:59:59`,
      operator: 'bwn',
    },
    player: {
      value: '',
      operator: 'in'
    },
    event: {
      value: '',
      operator: 'in'
    },
    offerPartnerLanding: null
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
