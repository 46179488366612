import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary900: '#0D47A1',
        primary800: '#1565C0',
        primary700: '#1976D2',
        primary600: '#1E88E5',
        primary500: '#2196F3',
        primary400: '#42A5F5',
        primary300: '#64B5F6',
        primary200: '#90CAF9',
        primary100: '#BBDEFB',
        primary50: '#E3F2FD',
        text500: '#00207A',
        gray900: '#111928',
        gray800: '#1F2A37',
        gray500: '#8D8D8D',
        gray200: '#E5E7EB',
        gray100: '#F3F4F6',
        green800: '#03543F',
        green500: '#0E9F6E',
        green100: '#DEF7EC',
        red500: '#F05252',
        orange500: '#FF5A1F',
        violet200: '#DDD6FE',
        violet50: '#F5F3FF',
        teal200: '#AFECEF',
        teal50: '#EDFAFA',
        blue300: '#84CAFF',
        orange200: '#FCD9BD',
        orange50: '#FFF8F1',
        blue500: '#3F83F8',
        primary: {
          base: '#00207A', // #3F83F8 old
          lighten1: '#7979E8',
          lighten2: '#9A9AEE',
          lighten3: '#BCBCF3',
          lighten4: '#CDCDF6',
          lighten5: '#DDDDF9',
          lighten6: '#EEEEFC',
          darken1: '#4646B8',
          darken2: '#35358E',
          darken3: '#252563',
          darken4: '#1C1C4E',
          darken5: '#141439',
          darken6: '#0B0B24',
          darken7: '#03030F',
        },
        secondary: '#EEEEFC',
        main: '#373737', // need remove
        error: {
          base: '#E92F2F',
          lighten1: '#ED5959',
          lighten2: '#F28282',
          lighten3: '#F6ACAC',
          lighten4: '#F8C1C1',
          lighten5: '#FBD5D5',
          lighten6: '#FDEAEA',
          darken1: '#BA2626',
          darken2: '#8C1C1C',
          darken3: '#5D1313',
          darken4: '#460E0E',
          darken5: '#2F0909',
          darken6: '#170505'
        },
        info: '#B9B9B9', // need remove
        success: {
          base: '#21C932',
          lighten1: '#4DD45B',
          lighten2: '#7ADF84',
          lighten3: '#A6E9AD',
          lighten4: '#BCEFC2',
          lighten5: '#D3F4D6',
          lighten6: '#E9FAEB',
          darken1: '#1AA128',
          darken2: '#14791E',
          darken3: '#0D5014',
          darken4: '#0A3C0F',
          darken5: '#07280A',
          darken6: '#031405'
        },
        warning: {
          base: '#F1D900',
          lighten1: '#F4E133',
          lighten2: '#F7E866',
          lighten3: '#F9F099',
          lighten4: '#FBF4B3',
          lighten5: '#FCF7CC',
          lighten6: '#FEFBE6',
          darken1: '#C1AE00',
          darken2: '#918200',
          darken3: '#605700',
          darken4: '#484100',
          darken5: '#302B00',
          darken6: '#181600'
        },
        white: '#FFFFFF',
        black: {
          base: '#000000',
          lighten1: '#ABABAB',
          lighten2: '#BCBCBC',
          lighten3: '#CDCDCD',
          lighten4: '#DDDDDD',
          lighten5: '#E6E6E6',
          lighten6: '#EEEEEE',
          lighten7: '#F6F6F6',
          darken1: '#8D8D8D',
          darken2: '#707070',
          darken3: '#525252',
          darken4: '#434343',
          darken5: '#353535',
          darken6: '#262626',
          darken7: '#171717'
        }
      },
    },
  },
})

export default vuetify
