<template>
  <form>
    <sticky-header class="d-flex align-center justify-space-between px-0">
      <slot name="header" :on-submit="onSubmit">
        <v-row align="center" class="px-1 px-md-3">
          <v-col cols="12" sm="6">
            <div class="black--text text--darken-6 text-h2 text-md-h1 pt-2 pb-0 py-sm-5 d-flex justify-start">
              {{ title }}
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex justify-start justify-sm-end flex-row flex-wrap flex-sm-nowrap">
            <slot name="leftActions" />
            <slot name="actions">
              <ui-button
                variant="white"
                :disabled="isLoading"
                class="ml-0 mr-3 mr-md-3 mx-md-3 mb-0 mb-md-0 form-entity-btn"
                @click="$emit('saveBack')"
              >
                {{ $t('base.saveBack') }}
              </ui-button>
              <ui-button
                v-if="checkPermission"
                :disabled="isLoading"
                class="form-entity-btn blue500"
                @click="onSubmit"
              >
                {{ $t('base.save') }}
              </ui-button>
            </slot>
          </v-col>
        </v-row>
      </slot>
    </sticky-header>
    <slot></slot>
  </form>
</template>

<script>
import StickyHeader from '@/components/ui/StickyHeader'
import { hasPermission } from '@/helpers/permission'
import {isEmpty} from "lodash";

export default {
  name: 'ui-form-entity',
  components: { StickyHeader },
  props: {
    title: {
      type: String,
      default: '',
    },
    pathName: {
      type: String,
      default: '',
    },
    permission: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkPermission() {
      return isEmpty(this.permission) ? true : this.hasPermission(this.permission)
    },
  },
  methods: {
    hasPermission,
    onSubmit() {
      this.$emit('onSubmit')
    },
  },
}
</script>

<style lang="scss">
  .form-entity-btn {
    width: 120px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: calc(50% - 6px)!important;
    }
  }
</style>
