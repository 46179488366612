import { DATE_FORM_FORMAT } from '@/constants/date'
import moment from 'moment'
export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    groupBy: 'offer',
    secondGroupBy: 'traffic_source',
    thirdGroupBy: null,
    fourthGroupBy: null,
    dateFrom: moment().format(DATE_FORM_FORMAT),
    dateTo: moment().format(DATE_FORM_FORMAT),
    secondDateTo: moment().format(DATE_FORM_FORMAT),
    firstDeposit: null,
    advertisers: '',
    partners: '',
    offers: '',
    countries: '',
    paymentModels: '',
    products: '',
    subId: '',
    landings: '',
    offerTags: '',
    partnerTags: '',
    trafficSources: '',
    mediaId: '',
    siteId: '',
    promocode: '',
    partnerGroups: null,
    clickId: '',
    streams: ''
  },
  sortBy: [],
  sortDesc: [],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
