<template>
  <v-container fluid class="px-1">
    <v-row align="center">
      <v-col cols="12">
        <div class="d-flex align-start align-sm-center justify-space-between flex-column flex-sm-row entity-wrapper px-md-2">
          <div class="black--text text--darken-6 text-h2 text-md-h1 pt-2 pt-sm-5 pb-5 custom-title">
            <span
              class="cursor-pointer"
              v-tooltip="{
                content: title,
                placement: 'top-end',
              }"
            >
              {{ title }}</span
            >
            <div class="cursor-pointer text-subtitle-1 font-weight-regular black--text text--darken-1 mt-2">
              {{ subtitle }}
            </div>
          </div>
          <div v-if="isShowActions" class="d-flex justify-center justify-sm-end flex-column flex-sm-row entity-btns">
            <slot name="actions">
              <slot name="leftActions" />

              <div class="d-flex">
                <ui-button
                  v-if="!hideFilter"
                  theme="outline"
                  class="ml-0 mx-0 mb-0 mb-md-0 list-entity-btn filter-btn gray800--text"
                  @click="onToggleFilter"
                  :w120="!hideDefaultCreate"
                  :w120sm100="hideDefaultCreate"
                >
                  <template #iconLeft>
                    <v-icon size="20" color="gray800">mdi-filter-variant</v-icon>
                  </template>
                  {{ $t('base.filters') }}
                </ui-button>

                <slot name="defaultCreate">
                  <ui-button
                    v-if="!hideDefaultCreate"
                    v-permission="btnCreate.permission"
                    class="list-entity-btn ml-3 blue500"
                    w120
                    @click="btnCreate.modal ? $emit('createModal') : $router.push({ name: btnCreate.path })"
                  >
                    <template #iconLeft>
                      <v-icon size="18">{{ btnCreate.icon || '' }}</v-icon>
                    </template>
                    {{ btnCreate.title }}
                  </ui-button>
                </slot>
              </div>

              <slot name="rightActions" />
            </slot>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="px-md-4">
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ui-list-entity',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: ''
    },
    isShowActions: {
      type: Boolean,
      default: true,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    hideDefaultCreate: {
      type: Boolean,
      default: false,
    },
    btnCreate: {
      type: Object,
      default: () => {
        return {
          path: '',
          title: '',
          icon: false,
          permission: {},
          modal: false
        }
      },
    },
  },
  methods: {
    onToggleFilter() {
      this.$emit('toggleFilter')
    },
  },
}
</script>

<style lang="scss">
.custom-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-entity-btn {
  //width: 165px;
  //width: 48%;
  width: calc(50% - 6px)!important;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 120px;
  }
  &.filter-btn {
    background-color: white;
  }
}
.entity-btns {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
  }
}
</style>
