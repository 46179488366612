export default [
  {
    path: '/cohort-analysis',
    name: 'pageCohortAnalysisList',
    meta: { title: 'cohortAnalysis.listTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['cohort_analysis_view'] },
    component: () => import('@/pages/cohort-analysis'),
  },
  {
    path: '/cohort-analysis-new',
    name: 'pageCohortAnalysisList',
    meta: { title: 'cohortAnalysis.listTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['cohort_analysis_view'] },
    component: () => import('@/pages/cohort-analysis'),
  },
]